import striptags from 'striptags';

export const isLocalePath = (url) => {
  const locales = {
    '/fr': 'fr-fr',
    '/de': 'de-de',
    '/jp': 'ja-jp',
    '/kr': 'ko-kr',
    '/cn': 'zh-cn',
    '/es': 'es-mx',
    '/pt': 'pt-br',
  };

  const hasTrailSlash = url.match(/^\/..\//) !== null;

  const res = locales[url.slice(0, 3)];

  return res !== '' && hasTrailSlash && typeof res !== 'undefined';
};

export const stringToId = (sIn) => {
  let s;

  if (typeof sIn === 'object' && typeof sIn[0] === 'string') {
    s = sIn[0];
  } else if (typeof sIn === 'string') {
    s = sIn;
  }

  return striptags(s)
    .toLowerCase()
    .trim()
    .replace(/'/g, '')
    .replace(/’/g, '')
    .replace(/"/g, '')
    .replace(/,/g, '')
    .replace(/!/g, '')
    .replace(/;/g, '')
    .replace(/:/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/\+/g, '')
    .replace(/-/g, '')
    .replace(/–/g, '')
    .replace(/\?/g, '')
    .replace(/\//g, '')
    .replace(/[\s]+/g, '-');
};