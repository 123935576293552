import React, { useEffect } from 'react';

export const Layout = ({ children }) => {
  useEffect(() => {
    //const hostName = window.location.host === 'localhost:3000' ? 'https://www.elastic.co' : ''
    const hostName = 'https://www.elastic.co'
    const navScript = document.createElement('script')
    navScript.src = `${hostName}/elastic-nav.js`
    navScript.type = 'text/javascript'
    document.body.appendChild(navScript)

    const footerScript = document.createElement('script')
    footerScript.src = `${hostName}/elastic-footer.js`
    footerScript.type = 'text/javascript'
    document.body.appendChild(footerScript)
  }, [])
  return (
    <div>
      <div id='elastic-nav' />
      <main>{children}</main>
      <div id='elastic-footer' />
    </div>
  );
};
