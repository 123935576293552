import React from "react";
import styled from "styled-components";
import { EwcText } from "@elastic/web-components-react";

import { Title } from "./Title";
import { Layout } from "./Layout";
import { Button } from "./Button";

export const CardLayout = ({ data }) => {
  const { title, cards } = data
  return (
    <Wrapper className="cardLayout">
      <Layout size="layout-05" />
      <div className="container">
        {title && (
          <div className="text-center">
            <Title tag="H2">{title}</Title>
            <Layout size="layout-05" />
          </div>
        )}
        <div className="row">
          {cards?.length && cards.map((item, index) => {
            return (
              <div key={index} className="col-md-4">
                <div className="contentWrapper">
                  <div className="content">
                    <div>
                      <img className="icon" src={item.icon} alt="" />
                    </div>
                    <div className="h6">{item.title}</div>
                    <EwcText tag="p" appearance="p2" alignment="left">{item.description}</EwcText>
                  </div>
                  <div className="cardFooter">
                    <Button className="stretched-link" href={item.cta?.href} type="tertiary">{item.cta.title}</Button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  &.cardLayout {
    .icon {
      width: 64px;
      height: 64px;
      margin-bottom: 16px;
    }

    .contentWrapper {
      position: relative;
      background-color: #FFF;
      padding: 24px;
      border: 1px solid #D4DAE5;
      border-radius: 8px;
      height: 100%;
      display: flex;
      flex-direction: column;
      
      .content {
        flex: 1;
      }

      .cardFooter {
        margin-top: 18px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.cardLayout {
      .col-md-4:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }
`;