import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser"
import { EwcText } from "@elastic/web-components-react";

import { TopicHeading } from "./TopicHeading";
import { Title } from "./Title"
import { Button } from "./Button"
import { Layout } from "./Layout";
import heroPNG from "../images/sustainability-hero.png"
import heroAVIF from "../images/sustainability-hero.avif"

export const Hero = ({
  topicHeading, title, description, ctaTitle, ctaHref, buttonType, imageSrc, imageAlt, reverse
}) => {
  return (
    <Wrapper className="hero">
      <div className="container">
        <div className="row text-center text-sm-start justify-content-center justify-content-sm-between align-items-center">
          <div className="col-12 col-sm-5 content">
            {topicHeading
              && <TopicHeading>{topicHeading}</TopicHeading>}
            {title
              && <Title tag="H1">{ReactHtmlParser(title)}</Title>}
            {description
              && <EwcText tag="p" appearance="p2" alignment="left">{ReactHtmlParser(description)}</EwcText>}
            <Layout size="layout-02" />
            {ctaTitle
              && (
                <Button
                  type={buttonType}
                  href={ctaHref}
                >
                  {ctaTitle}
                </Button>
              )
            }
          </div>
          <div className="col-12 col-sm-7">
            <div className="asset" role="img" aria-label="Hero illustration"></div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .asset {
    background-image: url(${heroPNG});
    background-image: image-set(
      url(${heroAVIF}) type("image/avif"),
      url(${heroPNG}) type("image/png"));
    background-size:100%;
    background-repeat:no-repeat;
    background-position:center;
    width:100%;
    height:480px;
    max-width:720px;
  }
  @media screen and (max-width: 991px) {
    .content {
      margin: 24px 0;
    }
  }
`;

