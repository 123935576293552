import React from "react";
import { isLocalePath } from "../common/utils";

export default class Link extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      href: props.href,
      preHref: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.href !== state.preHref) {
      return {
        href: props.href,
        preHref: state.href,
      };
    }
    return null;
  }

  componentDidMount() {
    const { href } = this.state;
    const pageLocale = window.location.pathname.slice(0, 3);
    const origHref = href;
    let hrefLoc;
    try {
      const h = href;
      if (typeof h !== "null") {
        hrefLoc = h.slice(0, 3);
      }
    } catch (error) {
      console.log("debug: error:", error);
    }

    if (
      isLocalePath(window.location.pathname) &&
      origHref.indexOf("#") === -1 &&
      hrefLoc !== "#" &&
      hrefLoc !== "jav" &&
      hrefLoc !== "htt" &&
      hrefLoc !== "mai" &&
      hrefLoc !== pageLocale &&
      !isLocalePath(`${hrefLoc}/`)
    ) {
      this.setState({ href: pageLocale + origHref });
    }
  }

  render() {
    const newProps = { ...this.props };
    delete newProps.href;
    const { className, children } = this.props;
    const { href } = this.state;

    return (
      <a className={className} href={href} {...newProps}>
        {children}
      </a>
    );
  }
}
