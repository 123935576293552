import React from "react";
import { Title } from "./Title";
import { Layout } from "./Layout";
import { Button } from "./Button";
import styled from "styled-components";

export const CardThumbnailLayout = ({ data }) => {
  const { title, cards } = data
  return (
    <Wrapper className="cardThumbnailLayout">
      <Layout size="layout-07" />
      <div className="container">
        {title && (
          <div className="text-center">
            <Title tag="H2">{title}</Title>
            <Layout size="layout-04" />
          </div>
        )}
        <div className="row">
          {cards?.length && cards.map((item, index) => {
            return (
              <div key={index} className="col-lg-4">
                <div className="contentWrapper">
                  <div className={item.imageType}>
                    <img src={item.image} className="img-fluid" alt="" />
                  </div>
                  <div className="content">
                    {item?.description && <p className="medium">{item.description}</p>}
                    <div className="cardFooter">
                      <Button className="stretched-link" href={item.cta?.href} type="tertiary">{item.cta.title}</Button>
                    </div>
                  </div>
                  
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  &.cardThumbnailLayout {
    .contentWrapper {
      position: relative;
      background-color: #FFF;
      border: 1px solid #D4DAE5;
      border-radius: 8px;
      height: 100%;
      display: flex;

      .logo, .thumbnail {
        width:150px;
        height:180px;
        min-width:150px;
        overflow:hidden;
      }

      .logo {
        background:#E6EBF2;
        align-content:center;
        text-align:center;
        img {
          width: 126px;
          height:auto;
        }
      }

      .thumbnail {
        border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        img {
          width: 100%;
          height: unset;
          min-height: 180px;
          object-fit: cover;
        }
      }

      img {
        transition:transform 250ms ease-in-out,-webkit-transform 250ms ease-in-out;
      }
      
      .content {
        display: flex;
        flex:1 0 0;
        flex-direction:column;
        justify-content:center;
        padding: 8px 16px;

        p {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow:hidden;
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    &.cardThumbnailLayout {
      .col-lg-4:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }
`;