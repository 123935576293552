import React from "react";
import styled from "styled-components";
import { EwcText } from "@elastic/web-components-react";

import { TopicHeading } from "./TopicHeading";
import { Title } from "./Title";
import { Button } from "./Button"
import { Layout } from "./Layout";
import weAreElasticPNG from "../images/we-are-elastic.png"
import weAreElasticAVIF from "../images/we-are-elastic.avif"

export const TextAsset = ({
  topicHeading, title, description, ctaTitle, ctaHref, buttonType, imageAlt,
}) => {
  return (
    <Wrapper className="TextAsset">
      <div className="gradientFeature"></div>
      <div className="content">
        <Layout size="layout-06" />
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-8 text-center">
              {topicHeading
                && <TopicHeading>{topicHeading}</TopicHeading>}
              {title
                && <Title tag="H3">{title}</Title>}
              {description
                && <EwcText tag="p" appearance="p2" alignment="center">{description}</EwcText>}
              <Layout size="layout-02" />
              {ctaTitle
                && (
                  <Button
                    type={buttonType}
                    href={ctaHref}
                  >
                    {ctaTitle}
                  </Button>
                )
              }
            </div>
          </div>
          <div>
            <Layout size="layout-05" />
            <div className="asset" role="img" aria-label={imageAlt}></div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .gradientFeature {
    background: linear-gradient(180deg, #FFFFFF 0%, #E6EBF2 100%);
    height:192px;
  }
  .content {
    background:#fff;
    margin-top:-96px;
    border-top-right-radius: 96px;
    border-top-left-radius: 96px;
  }
  .asset {
    background-image: url(${weAreElasticPNG});
    background-image: image-set(
      url(${weAreElasticAVIF}) type("image/avif"),
      url(${weAreElasticPNG}) type("image/png"));
    background-size:100%;
    background-repeat:no-repeat;
    width:100%;
    height:384px;
    max-width:1248px;
  }
  @media screen and (max-width: 768px) {
    .gradientFeature {
      height:120px;
    }
    .content {
      border-top-right-radius: 32px;
      border-top-left-radius: 32px;
    }
    .asset {
      height:150px;
    }
  }
`;

