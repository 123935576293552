import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { EwcText } from "@elastic/web-components-react";
import { TextAsset } from "./components/TextAsset";
import { Layout } from "./components/Layout";
import { Quote } from "./components/Quote";
import styled from "styled-components";
import { Title } from "./components/Title";
import { SustainabilityIllustrationSvg } from "./components/SustainabilityIllustrationSvg";
import { EsgpCarousel } from "./components/EsgpCarousel";
import { TextCta } from "./components/TextCta";
import { IllustrationSplitGrid } from "./components/IllustrationSplitGrid";
import { CardLayout } from "./components/CardLayout";
import { CardThumbnailLayout } from "./components/CardThumbnailLayout";
import { Hero } from "./components/Hero"

import AshKulkarni from "./images/AshKulkarni.png"
import SASB from "./images/SASB.svg"
import TCFD from "./images/TCFD.svg"
import UN from "./images/UN.png"
import Plant from "./images/icons/icon64_plant.svg"
import File from "./images/icons/icon64_file.svg"
import Shield from "./images/icons/icon64_shield.svg"
import Banner from "./images/esg-hero.png";
import plant from "./images/esg-environment-plant.svg"
import shield from "./images/esg-governance-shield.png";
import socialImage from "./images/social-image@2x.png"
import thumbnailEsgSocial from "./images/thumbnail-esg-social.png"
import MountainJPG from "./images/mountain-bg.jpg"
import MountainAVIF from "./images/mountain-bg.avif"
import TextCTAImage from "./images/esg-report-cover@2x.png"
import SustainabilityIllustrationSvgStatic from "./images/sustainability-illustration-static.svg";
import LogoCogstack from "./images/logo-cogstack.png";
import LogoAva from "./images/logo-ava.png";
import ThumbnailBlog from "./images/thumbnail-blog.jpeg";

export const HomePage = () => {
  const sustainabilityCards = [
    {
      title: "Our ambition",
      description: "Our work at Elastic to help build a more prosperous and sustainable future is ongoing, and we are dedicated to iterative growth, openness, and collaboration with our stakeholders."
    },
    {
      title: "Our progress",
      description: "In 2023, we built on our recent progress — from optimizing how we collect and manage data, to formalizing our long-term sustainability commitments. Above all, we listened to our key stakeholders and fine-tuned our approach."
    },
    {
      title: "Our opportunity",
      description: "We see huge potential in using our technology to help advance our customers’ own sustainability objectives. In Elastic, they will also find a like-minded sustainability partner that has the knowledge and ambition to join forces for greater efficiencies."
    }
  ]

  const EsgpAO = [
    {
      title: "Environment",
      content: {
        title: "Environmental impact",
        description: "<p>As a software company, we believe that our products can help enable the low-carbon economy transition. Customers can use our technology in efficient cloud environments, while our business can deliver services at scale, leveraging additional carbon efficiencies from our distributed-by-nature workplace design.</p><p>This year, we continued our data-driven approach, further optimizing how we collect and manage data and aligning with cutting edge technologies and calculation methodologies.</p><p>We have formally committed to set a science-based target through the Science Based Targets initiative (SBTi). Our commitment sets us on the path toward meaningful targets and reduction.</p>",
        stat: {
          number: "47<sup>%</sup>",
          caption: "reduction in GHG emissions intensity from FY20–FY23<sup>*</sup>",
          footnote: "* Total Scope 1 & Scope 2 location-based metric tons CO2e per full-time employee"
        },
        image: {
          url: plant,
          alt: "plant"
        }
      }
    },
    {
      title: "Social",
      content: {
        title: "Social",
        description: "<p>At Elastic, the power of infinite possibility is found in the talent and diversity of our people. As a distributed company, Elastic employs people around the globe. We focus on recruiting top, diverse talent and then engaging, supporting, developing, and retaining them with a flexible, supportive, and inclusive workplace that offers fair and consistent pay practices.</p><p>In 2023, we continued to enhance our best-in-class initiatives to support and enable Elasticians around the world. Our seven Elastician Resource Groups (ERGs) curated content and programming, intersecting multiple dimensions of diversity and providing awareness, education, and business and social impact.</p><p>Elastic’s philanthropic goodness program, Elastic Cares, ensures that Elasticians around the globe can donate, volunteer, and direct product donations to the charitable organizations that matter most to them.</p>",
        cta: {
          title: "Learn more about our ERGs",
          href: "https://www.elastic.co/careers/our-values"
        },
        image: {
          url: socialImage,
          alt: "esg-social-five-people"
        },
        fullWidth: true
      }
    },
    {
      title: "Governance",
      content: {
        title: "Governance",
        description: "<p>We are committed to the highest ethical standards and safeguarding of the data entrusted to us.</p><p>In 2023, we made significant strides in formalizing and expanding the governance of sustainability at Elastic through refined oversight structures and practices.</p><p>We relaunched Elastic’s Trust site as a new <a href=\"https://www.elastic.co/trust\">Elastic Trust Center</a> — a public one-stop shop for information on security, compliance, privacy, and resiliency for Elastic and the Elastic Cloud.</p>",
        cta: {
          title: "Learn about our ethics and compliance",
          href: "https://www.elastic.co/trust/business-integrity"
        },
        image: {
          url: shield,
          alt: "shield"
        }
      }
    }
  ]

  const illustrationGridObject = {
    titleTextOneColumn: {
      title: "Low carbon opportunities",
      text: "Elastic’s business strategy provides relatively low-carbon advantages, helping to minimize our impacts."
    },
    titleTextSecondColumn: [
      {
        title: "Distributed by design",
        text: "Our distributed workforce can have significantly lower carbon impacts when compared against emissions from commuting and large office footprints."
      },
      {
        title: "Efficient hyperscalers",
        text: "Our cloud-first approach leverages some of the most efficient data hosting available from the leading hyperscalers."
      }
    ]
  }

  const illustrationGridWithLogo = {
    titleTextOneColumn: {
      title: "Reporting methodology",
      text: "We review our progress against the Sustainability Accounting Standards Board (SASB) standards for the Software and IT Services industry. We also align with select United Nations Sustainable Development Goals (UN SDGs) and the Task Force on Climate-related Financial Disclosures (TCFD)."
    },
    titleTextSecondColumn: [
      {
        image: {
          url: SASB,
          alt: "SASB Standards"
        }
      },
      {
        image: {
          url: TCFD,
          alt: "TCFD"
        }
      },
      {
        image: {
          url: UN,
          alt: "Sustainable Development Goals"
        }
      }
    ]
  }

  const cardLayoutObject = {
    title: "More resources",
    cards: [
      {
        icon: Plant,
        title: "Environmental Policy",
        description: "Our Environmental Policy covers energy, carbon, travel, supply chain sustainability, and other topics.",
        cta: {
          href: "https://www.elastic.co/pdf/elastic-environmental-policy-2023.pdf",
          title: "Read our Environmental Policy"
        }
      },
      {
        icon: File,
        title: "2022 Sustainability Report",
        description: "Learn more about sustainability at Elastic.",
        cta: {
          href: "https://www.elastic.co/pdf/elastic-sustainability-report-2022.pdf",
          title: "Download the report"
        }
      },
      {
        icon: Shield,
        title: "Elastic Trust Center",
        description: "A one-stop shop for information on security, compliance, privacy, and resiliency.",
        cta: {
          href: "http://elastic.co/trust",
          title: "View the Elastic Trust Center"
        }
      }
    ]
  }

  const cardThumbnailLayoutObject = {
    title: "Product societal impact",
    cards: [
      {
        image: ThumbnailBlog,
        imageType: "thumbnail",
        description: "Universal Profiling: Detecting CO2 and energy efficiency",
        cta: {
          href: "https://www.elastic.co/blog/universal-profiling-detecting-co2-energy-efficiency",
          title: "Read the blog post"
        }
      },
      {
        image: LogoAva,
        imageType: "logo",
        description: "Making work, school, and daily life accessible for deaf and hard-of-hearing people",
        cta: {
          href: "https://www.elastic.co/customers/ava",
          title: "Read the customer story"
        }
      },
      {
        image: LogoCogstack,
        imageType: "logo",
        description: "Unlocking valuable healthcare data to improve patient outcomes and reduce clinical risk",
        cta: {
          href: "https://www.elastic.co/customers/nhs",
          title: "Read the customer story"
        }
      }
    ]
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === 13) {
      event.preventDefault()
      if (event?.target?.classList?.[0] === "sui-search-box__text-input") {
        const searchValue = event.target.value;
        window.location = `https://www.elastic.co/search/?q=${searchValue}&size=n_20_n`
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress, false)
    return (() => {
      window.removeEventListener("keypress", handleKeyPress)
    })
  })

  return (
    <div className="home"> 
    <Helmet>
      <title>Environmental, Social, and Governance | Elastic</title>
      <meta
        name="description"
        content="Discover how Elastic accelerates results that matters by staying committed to creating a sustainable environment for our employees, customers, and society at large."
      />
      <meta property="og:image" content={thumbnailEsgSocial} />
    </Helmet>
    <Layout size="layout-04" />
     <Hero
        title="Sustainability <br/>at Elastic"
        description="Our sustainability strategy is rooted in our belief in the power of data to solve challenging problems and to make a positive impact on our users and our community. Download our 2023 Sustainability Report to find out how we advance operational efficiency, innovation, and long-term resilience."
        ctaTitle="Download the report"
        ctaHref="https://www.elastic.co/pdf/elastic-sustainability-report-2023.pdf"
        buttonType="primary"
        imageSrc={Banner}
        imageAlt="esg-hero"
      />    
      <TextAsset
        title="We are Elastic"
        description="Elastic, The Search AI Company, enables everyone to securely harness Search AI to find the answers they need in real time using all their data, at scale — unleashing the potential of businesses and people."
        ctaTitle="Read our Source Code"
        ctaHref="https://www.elastic.co/about/our-source-code"
        buttonType="tertiary"
        imageAlt="we-are-elastic"
      />
      <Layout size="layout-07" />
      <Quote  
        quote="&quot;Our commitment is to pushing the boundaries of what we can achieve, not just in technology, but also in creating a positive impact on our users and community at large.&quot;"
        authorName="Ash Kulkarni"
        jobTitle="CEO"
        authorImage={AshKulkarni}
      />
      <Layout size="layout-07" />
      <Wrapper className="sustainabilityIllustration">
        <div className="container">
          <div className="titleText text-center">
            <Title tag="H2">Advancing sustainability</Title>
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-8">
                <EwcText tag="p" appearance="p1" alignment="center">Our approach to sustainability continues to evolve, and our commitment to our stakeholders reflects a more significant focus on data quality, resiliency, and innovation. This report highlights our key priorities, such as reducing our environmental impacts; upholding our dedication to diversity, equity, and inclusion; and strengthening our compliance and ethical processes.</EwcText>
              </div> 
            </div>
          </div>
        </div>
          <Layout size="layout-04" />
            <div className="container-fluid text-center"><img src={SustainabilityIllustrationSvgStatic} alt="" className="img-fluid" /></div>
            {/* <SustainabilityIllustrationSvg /> */}
          <Layout size="layout-05" />
        <div className="container">
          <div className="textCards"> 
            <div className="illustration-icon-grid-items illustration-icon-grid-3x1">
              {sustainabilityCards.map((item, index) => {
                return (
                  <div key={index} className="illustration-icon-grid-item one-column">
                    {item.title
                      && <Title tag="H3" className="h6">{item.title}</Title>}
                    {item.description
                      && <EwcText tag="p" appearance="p2" alignment="left">{item.description}</EwcText>}
                  </div>
                ) 
              })}
            </div>
          </div>  
        </div>
      </Wrapper>
      <Layout size="layout-07" />
      <EsgpCarousel data={EsgpAO} />
      <Wrapper className="cardsWrapper">
        <CardThumbnailLayout data={cardThumbnailLayoutObject} /> 
        <Layout size="layout-06" /> 
      </Wrapper>
      <Layout size="layout-07" />
      <TextCta
        image={TextCTAImage}
        text="Read about all the ways Elastic is celebrating diversity, promoting equity, and championing environmental management."
        ctaTitle="Download the report"
        ctaHref="https://www.elastic.co/pdf/elastic-sustainability-report-2023.pdf"
      />
      <Layout size="layout-07" />
      <Wrapper className="illustrationGridBg">
        <IllustrationSplitGrid data={illustrationGridObject} />
      </Wrapper>
      <Layout size="layout-07" />
        <IllustrationSplitGrid data={illustrationGridWithLogo} withLogo />
      <Wrapper className="cardsWrapper">
        <CardLayout data={cardLayoutObject} /> 
        <Layout size="layout-07" /> 
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  &.sustainabilityIllustration {
    width: 100%;

    .illustration-icon-grid-items {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: repeat(2,1fr);
      grid-template-rows: auto;
      grid-row-gap: 16px;
      grid-column-gap: 16px;
      margin-right: auto;
      margin-left: auto;

      .one-column {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .illustration-icon-grid-3x1 {
      display: grid;
      grid-auto-columns: 1fr;
      grid-column-gap: 64px;
      grid-row-gap: 64px; 
      grid-template-columns: repeat(3,1fr);
      grid-template-rows: auto;
    }
  }

  &.illustrationGridBg {
    background-image: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%), url(${MountainJPG});
    background-image: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%), image-set(
      url(${MountainAVIF}) type("image/avif"),
      url(${MountainJPG}) type("image/jpeg"));
    background-size: cover;
    background-position: bottom;
    height: 700px;
    padding-top: 50px;
    position: relative;
  }

  &.cardsWrapper {
    background-color: #F5F7FA;
  }

  &.note {
    background-color: #F5F7FA;

    span {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 767px) {
    &.sustainabilityIllustration {
      .illustration-icon-grid-3x1 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3,auto);
        grid-column-gap: 32px;
        grid-row-gap: 32px;
      }
    }

    .textCards {
      .title-wrapper {
        margin-top: 15px;
      }
    }
    &.illustrationGridBg {
      height: auto;
      background-position: center top;
      background-size: auto;
      border-radius: 0;

      &:after {
        content: none;
      }
    }
  }
`;