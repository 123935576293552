import React from "react";

/**
 * Layout scale is for module spacing and full-width spacing.
 */

export const Layout = (props) => {
  const { size } = props;
  const layoutSize = size || "layout-07";

  return <div className={`layout ${layoutSize}`} />;
};

// Layout.propTypes = {
//   /**
//   Sets the size | desktop > tablet > mobile <br>
//   layout-01 : 16px<br>
//   layout-02 : 24px > 16px<br>
//   layout-03 : 32px > 24px<br>
//   layout-04 : 48px > 32px<br>
//   layout-05 : 64px > 48px > 32px<br>
//   layout-06 : 80px > 64px > 48px<br>
//   layout-07 : 120px > 80px > 64px > 48px<br>
//   */
//   size: PropTypes.string,
// };
