/* Button
  https://web.elastic.co/532d53c48/p/0889d9-button/b/32e1a2
*/
import React from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import Link from "./Link";

export const Button = ({ children, type, size, href, className }) => {
  const btnType = type === "" ? "text-link" : `btn-${type}`;
  if (Array.isArray(children)) {
    children = children.filter(
      (item) => item !== "" && item !== null && item !== undefined
    );
  }
  return (
    <Wrapper>
      <Link href={href} className={`button ${className} ${btnType} btn-${size}`}>
        {type === "tertiary" || type === "glyph" ? (
          ReactHtmlParser(children)
        ) : (
          children
        )}
        {type === "tertiary" || type === "glyph" ? (
          <span className="icon-tertiary">
            <svg
              width={size === "small" ? "22" : "27"}
              height="15"
              viewBox="0 0 27 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="arrow"
                d="M0 7H25"
                stroke="#000000"
                strokeWidth="2"
              />
              <path
                className="arrow"
                d="M19 1L25 7L19 13"
                stroke="#000000"
                strokeWidth="2"
              />
            </svg>
          </span>
        ) : (
          ""
        )}
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .button {
    text-shadow: none;
    box-shadow: none;
    font-family:'MierB', 'Inter', Arial, sans-serif;
    &:hover {
      background: none;
    }
  }
  .btn-primary,
  .btn-secondary,
  .btn-blue,
  input.btn-primary,
  a.btn-primary:not([href]):not([tabindex]) {
    display: inline-flex;
    min-height: 50px;
    height: auto;
    justify-content: center;
    align-items: center;
    min-width: 140px;
    padding: 8px 24px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.025em;
    text-decoration: none;
    box-shadow: none;
    outline: none;
    border-radius: 4px;
    -webkit-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
    &.btn-small {
      font-size: 0.875rem;
      height: 40px;
      line-height: 35px;
      min-height: 50px;
    }
    &.btn-large {
      min-width: 200px;
      min-height: 60px;
      padding: 8px 24px;
    }
  }

  .btn-primary,
  .btn-blue,
  input.btn-primary,
  a.btn-primary:not([href]):not([tabindex]) {
    cursor: pointer;
    border: 2px solid #0077CC;
    background-color: #0077CC;
    color: #FFF;

    &:hover,
    &.hover {
      text-decoration: none;
      color: #FFF;
      background-color: #005A9E;
      border-color: #005A9E;
    }
    &:hover:active,
    &.active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-color: #005A9E;
    }
    &:focus,
    &:active,
    &:active:focus,
    &.active {
      text-decoration: none;
      color: #FFF;
      &:not(:disabled):not(.disabled) {
        text-decoration: none;
        color: #FFF;
      }
    }
  }

  .btn-secondary,
  a.btn-secondary:not([href]):not([tabindex]) {
    cursor: pointer;
    border: 1px solid #0077CC;
    background-color: transparent;
    color: #0077CC;

    &:hover,
    &.hover {
      text-decoration: none;
      background-color: rgba(0, 90, 158, 0.1);
      border-color: #005A9E;
      color: #005A9E;
    }

    &:focus,
    &:active,
    &:active:focus,
    &.active {
      text-decoration: none;
      background-color: rgba(0, 90, 158, 0.1);
      color: #005A9E;
      border-color: #1BA9F5;
      &:not(:disabled):not(.disabled) {
        text-decoration: none;
        background-color: rgba(0, 90, 158, 0.1);
        color: #005A9E;
        border-color: #1BA9F5;
      }
    }
  }

  // also lives in global.js for RTP
  .btn-description,
  .btn-tertiary,
  a.btn-tertiary:not([href]):not([tabindex]) {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: #0077CC;
    padding-right: 27px;
    white-space:unset;
    svg {
      width: 27px;
      margin-right: -27px;
      -webkit-transition: all 100ms ease-in;
      transition: all 100ms ease-in;
      left: 8px;
      position: relative;
      height: inherit;
      path {
        stroke: #0077CC;
      }
    }

    /* cursor: pointer;
    display: inline-flex;
    text-decoration: none;
    color: #0077CC;
    font-weight: 600;
    padding-right:0;

    svg {
      -webkit-transition: all 100ms ease-in;
      transition: all 100ms ease-in;
      position: relative;
      left: 8px;
      vertical-align: middle;
    } */
    .arrow {
      stroke: #0077CC;
    }

    &.btn-small {
      p {
        font-size: 0.875rem;
      }
    }

    &:hover,
    &:focus,
    &.hover,
    &:active,
    &.active {
      text-decoration: underline;
      color: #005A9E;
      svg {
        position: relative;
        left: 14px;
      }
      .arrow {
        stroke: #005A9E;
      }

      p {
        color: #005A9E;
      }
    }
  }

  .btn-text-link {
    &.no-underline {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
    &.btn-small {
      font-size: 0.875rem;
    }
    &.lighter-ink {
      color: #6B6F7F;
    }
  }

  .dark-mode-blue:not(.light-mode) {
    .btn-primary,
    input.btn-primary,
    button.btn-primary {
      color: #0077CC;
      border: 2px solid #FFF;
      background: #FFF;
      &:hover,
      &.hover {
        color: #0077CC;
        background-color: rgba(255, 255, 255, 0.9);
      }
      &:focus,
      &:active,
      &:active:focus,
      &.active {
        background-color: #FFF;
        color: #0077CC;
        border-color: #1BA9F5;
      }
    }
  }

  .dark-mode:not(.light-mode),
  .dark-mode-blue:not(.light-mode),
  .dark-mode-teal:not(.light-mode),
  .dark-mode-ink:not(.light-mode),
  .dark-mode-blurple:not(.light-mode) {
    /* .btn-primary,
    input.btn-primary,
    button.btn-primary {
        color:#0077CC;
        border:2px solid #FFF;
        background:#FFF;
        &:hover,
        &.hover {
            color:#0077CC;
            background-color: rgba(255, 255, 255, 0.9);
        }
        &:focus,
        &:active,
        &:active:focus,
        &.active {
            background-color: #FFF;
            color:#0077CC;
            border-color:#1BA9F5;
        }
    } */

    .btn-secondary {
      border-color: #FFF;
      color: #FFF;
      background: transparent;

      &:hover,
      &.hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:focus,
      &:active,
      &:active:focus,
      &.active {
        color: #FFF !important;
        background-color: transparent;
        border-color: #1BA9F5;
      }
    }
    .btn-description,
    .btn-tertiary,
    .btn-glyph,
    .cta-link {
      color: #36B9FF;

      .arrow {
        stroke: #36B9FF;
      }

      &:hover,
      &:focus,
      &.hover,
      &:active,
      &.active {
        text-decoration: underline;
        color: #8CD7FF;
        svg {
          position: relative;
          left: 14px;
        }
        .arrow {
          stroke: #8CD7FF;
        }
      }
    }
  }

  /* Dark Mode specific styles */
  .dark-mode.dark-mode-blue {
    .btn-description,
    .btn-tertiary,
    .btn-glyph,
    .cta-link {
      color: #FFF;

      &:after {
        border-color: transparent transparent transparent
          #FFF;
      }

      .arrow {
        stroke: #FFF;
      }

      &:hover,
      &:focus {
        color: #8CD7FF;
      }
    }
  }

  .dark-mode.dark-mode-ink {
    .btn-primary,
    input.btn-primary,
    button.btn-primary {
      border: 2px solid #0077CC;
      color: #FFF;
      background: #0077CC;

      &:hover,
      &.hover {
        color: #FFF;
        border: 2px solid rgba(255, 255, 255, 0) !important;
        background-color: #005A9E;
      }

      &:focus,
      &:active,
      &:active:focus,
      &.active {
        background-color: #005A9E !important;
        color: #FFF !important;
        border-color: #1BA9F5 !important;
      }
    }

    .btn-secondary {
      color: #36B9FF;
      border-color: #36B9FF;
    }
  }

  .dark-mode.dark-mode-blurple {
    .btn-primary,
    input.btn-primary,
    button.btn-primary,
    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      border: 2px solid #0077CC;
      color: #FFF;
      background: #0077CC;

      &:hover,
      &.hover {
        color: #FFF;
        border: 2px solid rgba(255, 255, 255, 0) !important;
        background-color: #005A9E;
      }

      &:focus,
      &:active,
      &:active:focus,
      &.active {
        background-color: #005A9E !important;
        color: #FFF !important;
        border-color: #1BA9F5 !important;
      }
    }

    .btn-secondary,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle {
      color: #36B9FF;
      border-color: #36B9FF;
    }
    .icon-tertiary {
      display: flex;
      align-items: center;
    }
  }
`;
