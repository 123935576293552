import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser"
import { EwcText } from "@elastic/web-components-react";
import { Layout } from "./Layout";
import { Title } from "./Title";
import { stringToId } from "../common/utils";

import FlourishBottomImage from "../images/bottom-flourish.png"
import FlourishTopImage from "../images/top-flourish.png"
import arrow from "../images/arrow-icon.png"
import { Button } from "./Button";

export const EsgpCarousel = ({ data }) => {
  const [ hashValue, setHashValue ] = useState("environment")

  const setActiveMenuItem = (id) => {
    const sidebarSectionHref = document.querySelectorAll(".sidebarSection li a")
    sidebarSectionHref?.length && sidebarSectionHref.forEach((element, index) => {
      if (element.getAttribute("href").includes(id)) {
        sidebarSectionHref[index].parentNode.classList.add("active");
      } else {
        sidebarSectionHref[index].parentNode.classList.remove("active");
      }
    })
  }

  const handleScroll = () => {
    const panelElements = document.querySelectorAll(".contentSection .panel")
    panelElements?.length && panelElements.forEach((element) => {
      const bounding = element.getBoundingClientRect();
  
      if (bounding.top >= 0 && bounding.top + 300 <= (window.innerHeight || document.documentElement.clientHeight)) {
        setActiveMenuItem(element.id)
      }
    })
  } 
    

  useEffect(() => {
    const hash = window.location.hash?.split("#")[1]
    if (hash) {
      setHashValue(hash)
    } 

    setActiveMenuItem(hashValue)  

    document.addEventListener("scroll", handleScroll)

    return () => { document.removeEventListener("scroll", handleScroll) }
  }, [hashValue])

  return (
    <div>
      <Wrapper className="FlourishTopImage d-none d-md-block">
        <img src={FlourishTopImage} alt="" className="img-fluid" />
      </Wrapper>
      <Wrapper className="esgpCarouselWrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-11">
              <div className="esgpCarousel">
                <div className="sidebarSection d-none d-md-block">
                  <ul>
                    {data?.length > 0 && data.map((sidebar, index) => {
                      return (
                        <li key={index} id={`${stringToId(sidebar.title)}_${index}`}>
                          <a id={index} href={`#${stringToId(sidebar.title)}`}>
                            <h2 className="h4">{sidebar.title}</h2>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="contentSection">
                  <div>
                    {data?.length > 0 && data.map((panel, index) => {
                      return (
                        <div key={index} className="panel" id={stringToId(panel.title)}>
                          {panel.content.title
                            && <Title tag="H3" className="h6">{panel.content.title}</Title>}
                          {panel.content.description
                            && <EwcText tag="p" appearance="p1" alignment="left">{ReactHtmlParser(panel.content.description)}</EwcText>}
                          {(panel?.content?.stat?.number || panel?.content?.stat?.footnote)
                            && (
                              <>
                                <Layout size="layout-04" />
                                <div className="stat">
                                  {panel.content.stat.number
                                    && <div className="statNumber font-face-SP">{ReactHtmlParser(panel.content.stat.number)}</div>}
                                  <div className="caption">
                                    {panel.content.stat.caption
                                      && <EwcText tag="p" appearance="p1" alignment="center">{ReactHtmlParser(panel.content.stat.caption)}</EwcText>}
                                    {panel.content.stat.footnote
                                      && (
                                        <><Layout size="layout-01" /><p className="footnote">{ReactHtmlParser(panel.content.stat.footnote)}</p></>
                                      )}
                                  </div>
                                </div>
                              </>
                            )
                          }
                          {panel.content.cluster
                            && (
                              <>
                                <Layout size="layout-04" />
                                <div className="d-flex">
                                  {panel.content.cluster.data
                                    && <div className="clusterNumber">{panel.content.cluster.data}</div>}
                                  {panel.content.cluster.description
                                    && <p className="medium">{panel.content.cluster.description}</p>}
                                </div>
                              </>
                            )}
                          {panel?.content?.cta?.title
                            && (
                              <>
                                <Layout size="layout-02" />
                                <Button type="tertiary" href={panel.content.cta.href}>{panel.content.cta.title}</Button>
                              </>
                            )}
                          {panel.content?.image?.url
                            && (
                              <>
                                <Layout size="layout-04" />
                                <div className={panel.content.fullWidth ? "text-center" : "image"}>
                                  <img src={panel.content.image.url} alt={panel.content.image.alt} className="img-fluid"/>
                                </div>
                              </>
                            )}
                          {index !== data.length - 1
                            && <Layout size="layout-07" />
                          }
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none">
          <Layout size="layout-06" />
        </div>
      </Wrapper>
      <Wrapper className="FlourishBottomImage d-none d-lg-block">
        <img src={FlourishBottomImage} alt="" className="img-fluid" />
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  &.FlourishTopImage {
    text-align:right;
    margin:0 96px -96px 0;
    position:relative;
    z-index:1;
  }
  &.FlourishBottomImage {
    margin:-144px 0 0 96px;
    position:absolute;
    z-index:1;
  }
  &.esgpCarouselWrapper {
    background-color: #101C3F;
    padding: 252px 0 237px 0;
    position: relative;

    .esgpCarousel {
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow-x: visible;

      .sidebarSection {
        height: 100vh;
        position: sticky;
        top: 30px;
        z-index: 1;
        ul {
          li {
            padding: 0 8px;
            margin: 0 0 40px 0;
            border: 0;
            border-left: 2px solid transparent;
            transition: all 400ms ease;
  
            a {
              text-decoration: none;
            }
            h2 {
              padding: 0;
            }
            &#environment_0 {
              h2 {
                color: #7DE2D1;
              }
            }
            &#social_1 {
              h2 {
                color: #F990C6;
              }
            }
            &#governance_2 {
              h2 {
                color: #FF957D;
              }
            }
            &#product-societal-impact_3 {
              h2 {
                color: #FFD836;
              }
            }
  
            &.active {
              background: none;
              border: 0;
              border-left: 2px solid #FFF;
              margin-left: 8px;
              border-radius: 0;
            }
          }
        }
      }

      .contentSection {
        color:#fff;
        width: 45%;
        position: relative; 

        .layout-07 {
          height: 128px;
        }

        .panel {
          &#environment h3 {
            color: #7DE2D1;
          }
          &#social h3 {
            color: #F990C6;
          }
          &#governance h3 {
            color: #FF957D;
          }

          .clusterNumber {
            font-family: Space Mono;
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            line-height: 150%;
            color: #FFD836;
            padding-right: 32px;
          }

          .stat {
            .paragraph-caption {
              color: #FFF;
              width: 60%;
              margin: 16px auto;
            }
            .statNumber {
              color: #FFF;
              font-size: 8.75rem;
              line-height: 120px;
              text-align: center;
              position: relative;
              width: fit-content;
              margin: 0 auto;
              font-weight: 400;
              letter-spacing: -0.04em;
              font-family: Space Mono;
      
              &:before {
                content: '';
                background-image: url(${arrow});
                position: absolute;
                width: 45px;
                background-position: left bottom;
                height: 40px;
                left: -35px;
                bottom: 0;
              }
            }
            .caption {
              max-width: 395px;
              margin: 16px auto 0 auto;
              text-align: center;

              sup {
                color: #7DE2D1;
              }

              .footnote {
                color: #7DE2D1;
                font-weight: 400;
                font-size: 0.625rem;
                line-height: 100%;
              }
            }
          }

          .image {
            width: 328px;
            height: auto;
            margin: 0 auto;
          }
      
          p {
            color: #FFF;
          }
      
          a, .btn-tertiary {
            color: #8CD7FF;

            .arrow {
              color: #8CD7FF;
              stroke: #8CD7FF;
            }

            &:hover {
              color: #1BA9F5;

              .arrow {
                color: #1BA9F5;
                stroke: #1BA9F5;
              }
            }
            p {
              color: inherit;
            }
          }
        }
      }
    }
  } 

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    &.esgpCarouselWrapper {
      .esgpCarousel {
        .contentSection {
          .panel {
            .stat {
              .statNumber {
                font-size: 6.25rem;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.esgpCarouselWrapper {
      padding: 50px 0px 0px;

      .esgpCarousel {
        .contentSection {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    &.esgpCarouselWrapper {
      .esgpCarousel {
        .contentSection {
          .panel {
            .stat {
              .statNumber {
                font-size: 6.25rem;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 425px) {
    &.esgpCarouselWrapper {
      .esgpCarousel {
        .contentSection {
          .panel {
            .image {
              width: 100%;
              height: auto;
              text-align: center;
            }
          }
        }
      }
    }
  }
`;