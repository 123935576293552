import React from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { Title } from "./Title";

export const TextCta = ({ image, text, ctaTitle, ctaHref }) => {
  return (
    <Wrapper className="textCtaWrapper">
      <div className="container">
        <div className="textCta">
          {image && <div className="image"><img src={image} alt="2023 Sustainability Report Cover" /></div>}
          <div className="text">
            <Title tag="H2" className="h5">{text}</Title>
          </div>
          <div className="cta">
            <Button href={ctaHref} type="primary" size="large">{ctaTitle}</Button>
          </div>
        </div>
      </div>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  .textCta {
    padding: 32px;
    border: 1px solid #E6EBF2;
    background: linear-gradient(89.4deg, #E6EBF2 1.48%, rgba(255, 255, 255, 0) 99.57%);
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .image {
      position:relative;
      width:313px;
      margin-top:-68px;
      img {
        width:313px;
      }
    }

    .text {
      flex: 1;
      padding-left:72px;
    }

    .h5 {
      padding-bottom:0;
    }

    .cta {
      padding:0 16px;
    }
  }

  @media screen and (max-width: 991px) {
    .textCta {
      display: block;
      padding: 32px;
      text-align: center;
      .image {
        margin:-68px auto 32px auto;
      }
      .text {
        max-width: 100%;
        padding:0 0 32px 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .textCta {
      .image {
        margin-left:-12px;
        img {
          width:100%;
        }
      }
      .text {
        text-align:left;
      }
    }
  }
`;