import React from "react";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import { stringToId } from "../common/utils";

export const Title = (props) => {
  const { children, tag, className, id } = props;
  let kids;
  if (typeof children === "string") {
    kids = ReactHtmlParser(children);
  } else {
    kids = children;
  }

  return (
    <Wrapper className="title-wrapper">
      <a id={id ? stringToId(id) : stringToId(kids)} />
      {tag === "H1" && <h1 className={className}>{kids}</h1>}
      {tag === "H2" && <h2 className={className}>{kids}</h2>}
      {tag === "H3" && <h3 className={className}>{kids}</h3>}
      {tag === "H4" && <h4 className={className}>{kids}</h4>}
      {tag === "H5" && <h5 className={className}>{kids}</h5>}
      {tag === "H6" && <h6 className={className}>{kids}</h6>}
      {!tag && <h2 className={className}>{kids}</h2>}
    </Wrapper>
  );
};

const Wrapper = styled.div`

  &.title-wrapper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      margin: 0;
      box-sizing: border-box;
      display: block;
      position: relative;
      font-weight: 800;
      color: #343741 ; 
      a {
        font-weight: 700;
        text-decoration: none;
        font-weight: unset;
        &:hover {
          text-decoration: none;
        }
      }
    }
    h1, .h1 {
      font-size: 4.5rem;
      line-height: 120%;
      letter-spacing: -0.04em;
      padding-bottom: 32px;
      font-weight: 400;
    }
    h2, .h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 3.75rem;
      line-height: 120%;
      letter-spacing: -0.04em;
      padding-bottom: 16px;
    }
    h3, .h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 3rem;
      line-height: 120%;
      letter-spacing: -0.04em;
    }
    h4, .h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 2.25rem;
      line-height: 120%;
      letter-spacing: -0.04em;
      padding-bottom: 16px;
    }
    h5, .h5 {
      font-size: 1.625rem;
      line-height: 28px;
      padding-bottom: 16px;
      font-weight:400;
      letter-spacing: normal;
    }
    h6, .h6 {
      font-size: 1.5rem;
      line-height: 120%;
      padding-bottom: 16px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: -0.02em;
    }
  }

  

  .dark-mode:not(.light-mode),
  .dark-mode-blue:not(.light-mode),
  .dark-mode-blurple:not(.light-mode),
  .dark-mode-teal:not(.light-mode),
  .dark-mode-ink:not(.light-mode) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p.h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: #FFF;
    }
  }
  .light-mode {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p.h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: #000;
    }
  }

  .blog,
  .press-detail .press-content {
    h2 {
      margin-top: 64px;
    }
    h3,
    h4,
    h5,
    h6 {
      margin-top: 32px;
    }
  }

  @media screen and (max-width: 991px) {
    &.title-wrapper {
      h1 {
        font-size: 64px;
        line-height: 72px;
        padding-bottom: 24px;
      }
      h2 {
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 16px;
      }
      h3,
      h4 {
        font-size: 24px;
        line-height: 28px;
        padding-bottom: 16px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &.title-wrapper {
      h5 {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 16px;
      }
      h6 {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 16px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.title-wrapper {
      h1 {
        font-size: 48px;
        line-height: 58px;
      }
    }
  }
`;
