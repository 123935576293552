import React from "react";
import styled from "styled-components";

export const TopicHeading = ({ children, tag, className, color }) => (
  <Wrapper>
    {tag === "H1" ? (
      <h1 className={`topic-heading ${className ? className : ''} ${color ? color : ''}`}>{children}</h1>
    ) : (
      <h2 className={`topic-heading ${className ? className : ''} ${color ? color : ''}`}>{children}</h2>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  h1.topic-heading,
  h2.topic-heading {
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 24px;
    padding-bottom: 16px;
    text-transform: uppercase;
    color: #20377D;

    &.small {
      font-size: 12px;
      line-height: 18px;
      padding-bottom: 8px;
    }
  }
  h1.topic-heading.black,
  h2.topic-heading.black {
    color: #000;
  }

  h1.topic-heading.small,
  h2.topic-heading.small {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 8px;
  }

  :global(.dark-mode:not(.light-mode)),
  .dark-mode-blue:not(.light-mode),
  .dark-mode-teal:not(.light-mode),
  .dark-mode-ink:not(.light-mode) {
    h1.topic-heading,
    h2.topic-heading {
      color: #FFF !important;
    }
  }

  .light-mode {
    h1.topic-heading,
    h2.topic-heading {
      color: #6B6F7F;
    }
  }

  @media screen and (max-width: 767px) {
    h1.topic-heading,
    h2.topic-heading {
      padding-bottom: 8px;
    }
  }
`;
