import React from "react";
import styled from "styled-components";
import { EwcText } from "@elastic/web-components-react";
import { Title } from "./Title";
import { Layout } from "./Layout";

export const IllustrationSplitGrid = ({ data, withLogo }) => {
  const { titleTextOneColumn, titleTextSecondColumn } = data
  return (
    <Wrapper className="illustrationSplitGrid">
      <div className="container">
        <div className={withLogo ? "illustration-icon32-split-grid-2x2-left" : "illustration-icon32-split-grid-2x2-container"}>
          <div className={withLogo ? "title-text-one-column" : "title-text-one-column"}>
            {titleTextOneColumn.title && <Title tag="H2">{titleTextOneColumn.title}</Title>}
            {titleTextOneColumn.text && <EwcText tag="p" appearance={withLogo ? "p2" : "p1"} alignment="left">{titleTextOneColumn.text}</EwcText>}
          </div>
          <div className="illustration-icon-grid-items illustration-icon32-split-grid-2x2">
            {titleTextSecondColumn?.length && titleTextSecondColumn.map((content, index) => {
              return (
                <div key={index} className={content.logoUrl ? "illustration-icon-grid-item" : "illustration-icon-grid-item"}>
                  {content.title && <Title tag="H3" className="h6">{content.title}</Title>}
                  {content.text && <EwcText tag="p" appearance="p2" alignment="left">{content.text}</EwcText>}
                  {content?.image?.url
                    && (
                      <div className="logo" id={`logo_${index}`}>
                        <img className="img-fluid" src={content.image.url} alt={content.image.alt} />
                      </div>
                    )}
                </div>
              )
            })}
          </div>
        </div>
        {withLogo && <Layout size="layout-07" />}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  &.illustrationSplitGrid {
    .logo {
      width: auto;
      height: auto;
      margin: 0 auto;

      &#logo_0 {
        width: 155px;
      }
      &#logo_1 {
        width: 109px;
      }
      &#logo_2 {
        width: 112px;
      }
    }

    .illustration-icon32-split-grid-2x2-container {
      display: grid;
      grid-template-columns: 0.8fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 64px;
      grid-row-gap: 64px;
      align-items: center;

      .illustration-icon-grid-items {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: auto;
        margin-right: auto;
        margin-left: auto;
      }
      .illustration-icon32-split-grid-2x2 {
        grid-column-gap: 64px;
        grid-row-gap: 64px;

        .illustration-icon-grid-item {
          display: flex;
          flex-direction: column;
          position: relative;
        }
      }
    }

    .illustration-icon32-split-grid-2x2-left {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      gap: 64px;
      align-items: center;

      .illustration-icon-grid-items {
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto;
        margin-right: auto;
        margin-left: auto;
        align-items: center;
      }
      .illustration-icon32-split-grid-2x2 {
        grid-column-gap: 32px;
        grid-row-gap: 64px;

        .illustration-icon-grid-item {
          display: flex;
          flex-direction: column;
          position: relative;
        }
      }
    }
  }

  @media screen and (max-width: 991px) and (min-width: 768px) {
    &.illustrationSplitGrid {
      .illustration-icon32-split-grid-2x2-left {
        .illustration-icon-grid-items {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.illustrationSplitGrid {
      .illustration-icon32-split-grid-2x2-container, .illustration-icon32-split-grid-2x2-left {
        grid-row-gap: 32px;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;

        .illustration-icon32-split-grid-2x2 {
          grid-column-gap: 32px;
          grid-row-gap: 32px;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    &.illustrationSplitGrid {
      .illustration-icon32-split-grid-2x2-container, .illustration-icon32-split-grid-2x2-left {
        .illustration-icon32-split-grid-2x2 {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(4,auto);
        }
      }
    }
  }
`;