import React from "react";
import styled from "styled-components";
import { Layout } from "./Layout";

import quoteImg from "../images/quotes.svg"

export const Quote = ({
  quote, authorName, jobTitle, authorImage
}) => {
  return (
    <Wrapper className="quoteWrapper">
      <Layout size="layout-06" />
      <div className="container">
        <div className="quote">
          <div className="quoteImg"><img src={quoteImg} alt="" /></div>
          <div className="content">{quote}</div>
          <div className="quoteDetails">
            {authorImage && <img src={authorImage} alt={authorName} />}
            {authorName
              && (
                <h6>{authorName}, <span className="jobTitle">{jobTitle}</span></h6>
              )}
          </div>
        </div>
      </div>
      <Layout size="layout-06" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  &.quoteWrapper {
    background: #101C3F;
    border-bottom-right-radius: 96px;
    border-bottom-left-radius: 96px;
    padding: 8px 15px;
    text-align:center;
  }
  .quote {
    position: relative;
    .quoteImg {
      margin-bottom:38px;
    }

    .content {
      color: #FFF;
      font-family: 'MierB', 'Inter', Arial, sans-serif;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 52px;
      text-align: center;
      width: auto;
      max-width: 854px;
      position: relative;
      margin: 0 auto;
    }

    .quoteDetails {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 48px;

      .jobTitle {
        font-weight: 400;
      }

      img {
        border-radius: 50%;
        margin-right: 10px;
        width: 60px;
        height: 60px;
        border:2px solid #fff;
      }
      
      h6, p {
        color: #fff;
        padding: 0;
        margin: 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    &.quoteWrapper {
      border-bottom-right-radius: 32px;
      border-bottom-left-radius: 32px;
    }

    .quote {
      .content {
        font-size: 2.25rem;
        line-height: 43.2px;
        width: 100%;
      }

      .quoteDetails {
        display: block;

        img {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
`;